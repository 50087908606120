import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Send, CheckCircle, AlertTriangle, X, MessageSquare, Terminal, ChevronLeft, Shield, Zap, MouseIcon, Brain } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Confetti from 'react-confetti';

// Reusable Button Component with updated styling
const Button = ({ onClick, children, variant = "primary", className = "", disabled }) => {
    const baseStyles = "px-4 py-2 rounded font-bold transition-all duration-200 flex items-center gap-2 disabled:opacity-50 border-2";
    const variants = {
        primary: "bg-black hover:bg-gray-800 text-white border-black",
        secondary: "bg-white hover:bg-gray-100 text-black border-black",
        danger: "bg-red-100 hover:bg-red-200 text-red-900 border-red-500"
    };

    return (
        <motion.button
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.02 }}
            onClick={onClick}
            disabled={disabled}
            className={`${baseStyles} ${variants[variant]} ${className}`}
        >
            {children}
        </motion.button>
    );
};

// Toast system with updated styling
function ToastContainer({ toasts, removeToast }) {
    return (
        <div className="fixed top-4 right-4 z-50 flex flex-col items-end space-y-2">
            <AnimatePresence>
                {toasts.map((toast) => (
                    <motion.div
                        key={toast.id}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 50 }}
                        transition={{ duration: 0.3 }}
                        className="bg-black text-white px-4 py-3 rounded border-2 border-red-500 shadow-lg flex items-center gap-2 w-64"
                    >
                        <AlertTriangle className="w-4 h-4 text-red-500" />
                        <span className="text-sm break-words">{toast.message}</span>
                        <button
                            onClick={() => removeToast(toast.id)}
                            className="ml-auto text-gray-400 hover:text-white"
                        >
                            <X className="w-4 h-4" />
                        </button>
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
}

// Attempt Ended Modal with updated styling
function AttemptEndedModal({ onClose }) {
    return (
        <motion.div
            className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.div
                className="bg-white rounded max-w-md w-full p-6 border-2 border-red-500"
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
            >
                <div className="mb-4 flex items-center justify-between">
                    <h3 className="text-lg font-bold text-red-600 flex items-center gap-2">
                        <Shield className="w-5 h-5" /> System Terminated
                    </h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <p className="text-gray-700 leading-relaxed mb-6 font-mono">
                    ALERT: Connection terminated. Challenge attempt no longer active.
                    Initiate new challenge or terminate session.
                </p>
                <div className="flex justify-end">
                    <Button variant="secondary" onClick={onClose}>
                        Terminate
                    </Button>
                </div>
            </motion.div>
        </motion.div>
    );
}

// A new "No Account" modal to show if user fails /api/verify
function NoAccountModal({ onClose }) {
    return (
        <motion.div
            className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.div
                className="bg-white rounded max-w-md w-full p-6 border-2 border-black"
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.95, opacity: 0 }}
            >
                <div className="mb-4 flex items-center justify-between">
                    <h3 className="text-lg font-bold text-red-600 flex items-center gap-2">
                        <AlertTriangle className="w-5 h-5" /> No Valid Account
                    </h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="w-5 h-5" />
                    </button>
                </div>
                <p className="text-gray-700 leading-relaxed mb-6 font-mono">
                    We couldn't verify your account. You might not be logged in, or your token is invalid.
                    Please return to main menu or log in again.
                </p>
                <div className="flex justify-end gap-3">
                    <Button variant="secondary" onClick={onClose}>
                        GO BACK
                    </Button>
                </div>
            </motion.div>
        </motion.div>
    );
}

// Loading Spinner with updated styling
const LoadingSpinner = () => (
    <div className="flex items-center justify-center p-8">
        <motion.div
            className="w-12 h-12 border-4 border-black border-t-transparent rounded-full"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        />
    </div>
);

// Main Component
function PromptPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const promptId = searchParams.get('id');

    const [prompt, setPrompt] = useState(null);
    const [attemptId, setAttemptId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [toasts, setToasts] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [existingAttempt, setExistingAttempt] = useState(false);
    const [showAttemptEndedModal, setShowAttemptEndedModal] = useState(false);
    const [showSolutionModal, setShowSolutionModal] = useState(false);
    const [solutionInput, setSolutionInput] = useState('');
    const [solutionFeedback, setSolutionFeedback] = useState(null);
    const [confettiActive, setConfettiActive] = useState(false);
    const [showBlandModal, setShowBlandModal] = useState(false);
    const [blandName, setBlandName] = useState('');
    const [blandEmail, setBlandEmail] = useState('');
    const [blandLinkedIn, setBlandLinkedIn] = useState('');
    const [blandSuccessMessage, setBlandSuccessMessage] = useState('');
    const [blandErrorMessage, setBlandErrorMessage] = useState('');

    // New state for "No Account" modal
    const [showNoAccountModal, setShowNoAccountModal] = useState(false);

    const containerRef = useRef(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const addToast = (message) => {
        const id = Date.now();
        setToasts((prev) => [...prev, { id, message }]);
        setTimeout(() => removeToast(id), 3000);
    };

    const removeToast = (id) => {
        setToasts((prev) => prev.filter((t) => t.id !== id));
    };

    // 1) Verify user
    useEffect(() => {
        async function checkUser() {
            const token = localStorage.getItem('jwt');
            if (!token) {
                setShowNoAccountModal(true);
                return;
            }
            try {
                const res = await fetch('/api/verify', {
                    method: 'GET',
                    headers: { Authorization: token },
                });
                if (!res.ok) {
                    // If 401 or 404, user invalid
                    setShowNoAccountModal(true);
                } else {
                    // valid user
                    const data = await res.json();
                    console.log("Verified user:", data.user);
                }
            } catch (err) {
                console.error("Error verifying user:", err);
                setShowNoAccountModal(true);
            }
        }

        checkUser();
    }, []);

    // 2) Attempt check
    useEffect(() => {
        const checkActiveAttempt = async () => {
            try {
                const token = localStorage.getItem('jwt');
                if (!token) throw new Error('User not authenticated');

                const attemptRes = await fetch(`/api/prompts/checkActiveAttempt?promptId=${promptId}`, {
                    headers: { Authorization: token }
                });
                const attemptData = await attemptRes.json();
                if (!attemptRes.ok) throw new Error(attemptData.error || 'Failed to check attempt');

                if (attemptData.existing_attempt) {
                    setExistingAttempt(true);
                    setAttemptId(attemptData.attempt_id);
                    setMessages(
                        attemptData.messages.map((m) => ({
                            role: m.from_role === 'user' ? 'user' : 'ai',
                            content: m.message
                        }))
                    );
                }

                const promptRes = await fetch(`/api/prompts?id=${promptId}`);
                const promptJson = await promptRes.json();
                if (!promptRes.ok) throw new Error(promptJson.error || 'Failed to fetch prompt');

                setPrompt(promptJson.prompt);
                setLoading(false);
            } catch (err) {
                addToast(err.message);
                setLoading(false);
            }
        };

        if (promptId) {
            checkActiveAttempt();
        }
    }, [promptId]);

    const handleStartChallenge = async () => {
        const token = localStorage.getItem('jwt');
        if (!token) {
            addToast('Please log in before starting a challenge.');
            return;
        }
        try {
            const res = await fetch('/api/prompt_attempts', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify({ promptId })
            });
            const data = await res.json();
            if (res.ok) {
                setAttemptId(data.attemptId);
                setMessages([]);
                setShowModal(false);
                setExistingAttempt(false);
            } else {
                addToast(data.error || 'Error starting challenge.');
                setShowModal(false);
            }
        } catch (err) {
            addToast(err.message);
            setShowModal(false);
        }
    };

    const handleStartFresh = async () => {
        const token = localStorage.getItem('jwt');
        if (!token) {
            addToast('Please log in before starting a new attempt.');
            return;
        }
        if (!attemptId || !promptId) {
            addToast('No existing attempt or prompt found.');
            return;
        }

        try {
            const res = await fetch('/api/prompts/restart', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify({ oldAttemptId: attemptId, promptId })
            });
            const data = await res.json();
            if (res.ok) {
                addToast('Started a fresh attempt!');
                setAttemptId(data.newAttemptId);
                setMessages([]);
                setShowModal(false);
                setExistingAttempt(false);
            } else {
                addToast(data.error || 'Error restarting attempt.');
            }
        } catch (err) {
            addToast(err.message);
        }
    };

    const handleResumeAttempt = () => {
        setShowModal(false);
    };

    const handleSendMessage = async () => {
        if (!inputMessage.trim()) return;
        if (!attemptId) {
            addToast('Cannot send a message without starting a challenge.');
            return;
        }

        const token = localStorage.getItem('jwt');
        if (!token) {
            addToast('Please log in before continuing the challenge.');
            return;
        }

        const userMsg = { role: 'user', content: inputMessage };
        setMessages((prev) => [...prev, userMsg]);
        const tempInput = inputMessage;
        setInputMessage('');

        try {
            const res = await fetch('/api/prompts/continue', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify({ attemptId, userMessage: tempInput })
            });
            const data = await res.json();
            if (res.ok) {
                setMessages((prev) => [...prev, { role: 'ai', content: data.message }]);
            } else {
                if (data.error?.includes('no longer live')) {
                    setShowAttemptEndedModal(true);
                }
                addToast(data.error || 'Error continuing prompt.');
            }
        } catch (err) {
            addToast(err.message);
        }
    };

    const handleSolutionSubmit = async () => {
        if (!solutionInput.trim()) return;
        if (!attemptId) {
            addToast('Cannot submit solution without starting a challenge.');
            return;
        }

        const token = localStorage.getItem('jwt');
        if (!token) {
            addToast('Please log in before submitting a solution.');
            return;
        }

        try {
            const res = await fetch('/api/prompts/submitSolution', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify({ attemptId, userSolution: solutionInput })
            });
            const data = await res.json();
            if (res.ok) {
                setSolutionFeedback(data.message);
                setShowSolutionModal(false);
                setSolutionInput('');

                if (data.isCorrect) {
                    setConfettiActive(true);
                    setTimeout(() => setConfettiActive(false), 5000);
                    setShowBlandModal(true);
                }
            } else {
                setSolutionFeedback(data.error || 'Failed to submit solution.');
            }
        } catch (err) {
            setSolutionFeedback('An error occurred. Try again.');
        }
    };

    const handleSendToBland = async () => {
        setBlandSuccessMessage('');
        setBlandErrorMessage('');

        if (!blandName.trim() || !blandEmail.trim()) {
            setBlandErrorMessage('Name and Email are required!');
            return;
        }

        const token = localStorage.getItem('jwt');
        if (!token) {
            setBlandErrorMessage('User not authenticated');
            return;
        }

        try {
            const res = await fetch('/api/prompts/sendToBland', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify({
                    attemptId,
                    name: blandName,
                    email: blandEmail,
                    linkedin: blandLinkedIn
                })
            });
            const data = await res.json();
            if (res.ok) {
                setBlandSuccessMessage(data.message);
            } else {
                setBlandErrorMessage(data.error || 'Failed to notify Bland.');
            }
        } catch (err) {
            console.error('Error sending to Bland:', err);
            setBlandErrorMessage('An unexpected error occurred.');
        }
    };

    return (
        <div ref={containerRef} className="min-h-screen bg-slate-50 text-gray-900 flex flex-col relative font-mono">
            <ToastContainer toasts={toasts} removeToast={removeToast} />

            {confettiActive && (
                <Confetti
                    width={containerRef.current?.clientWidth || window.innerWidth}
                    height={containerRef.current?.clientHeight || window.innerHeight}
                    recycle={false}
                    numberOfPieces={200}
                    colors={['#000', '#333', '#666']}
                />
            )}

            {/* Header */}
            <header className="bg-white border-b-2 border-black py-4 px-6 sticky top-0 z-10">
                <div className="max-w-5xl mx-auto flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <Button
                            variant="secondary"
                            onClick={() => navigate('/')}
                            className="!px-3"
                        >
                            <ChevronLeft className="w-5 h-5" />
                            BACK
                        </Button>
                        <div className="flex items-center gap-2">
                            <Terminal className="w-5 h-5" />
                            <h1 className="text-lg font-bold">CAPTURE THE FLAG</h1>
                        </div>
                    </div>
                    {prompt?.title && (
                        <div className="text-sm font-bold">
                            SCENARIO #{promptId}: {prompt.title}
                        </div>
                    )}
                </div>
            </header>

            {/* Main Content */}
            {loading ? (
                <div className="flex-1 flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="flex-1 flex flex-col max-w-5xl w-full mx-auto">
                    {/* Prompt Description */}
                    {prompt?.description && (
                        <div className="p-4 m-4 rounded bg-white border-2 border-black">
                            <h2 className="font-bold text-base mb-2 flex items-center gap-2">
                                <Terminal className="w-4 h-4" />
                                Challenge BRIEF.
                            </h2>
                            <p className="leading-relaxed">
                                {prompt.description}
                            </p>
                            <p className="leading-relaxed mt-4 font-sans text-lg">
                                <span className="font-bold">How does this work?</span> There is a hidden prompt in which the AI is instructed not to reveal a code word or short (less than 3 word) phrase. Your job is to get the AI to reveal its code word, then submit solution below!
                            </p>
                        </div>
                    )}

                    {/* Chat Messages */}
                    <div
                        ref={chatContainerRef}
                        className="flex-1 overflow-y-auto px-6 pb-32"
                    >
                        <div className="space-y-4 py-6 font-sans">
                            {messages.map((message, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    {message.role !== 'user' && (
                                        <div
                                            className={` w-8 h-8 mx-4 rounded-full flex ${message.role === 'user'
                                                    ? 'bg-none '
                                                    : 'bg-white border border-gray-400 '
                                                }`}
                                        >
                                            <Brain className='mx-auto my-auto' />
                                        </div>
                                    )}
                                    <div
                                        className={`max-w-[55%] p-3 flex rounded break-words ${message.role === 'user'
                                                ? 'bg-gray-200 border border-gray-500 text-black '
                                                : 'bg-white text-black border border-gray-500'
                                            }`}
                                    >
                                        {message.content}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>

                    {/* Chat Input Bar */}
                    <div className="fixed bottom-0 left-0 right-0 bg-white border-t-2 border-black">
                        <div className="max-w-5xl mx-auto p-4 flex gap-3">
                            <div className="flex-1 relative w-3/4">
                                <motion.input
                                    whileFocus={{ scale: 1.01 }}
                                    type="text"
                                    value={inputMessage}
                                    onChange={(e) => setInputMessage(e.target.value)}
                                    placeholder="ENTER PROMPT..."
                                    className="w-full p-3 pr-20 rounded bg-slate-100 border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black font-mono"
                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                />
                                <motion.button
                                    whileTap={{ scale: 0.93 }}
                                    whileHover={{ scale: 1.05 }}
                                    onClick={handleSendMessage}
                                    className="absolute right-3 top-1/2 -translate-y-1/2 text-black hover:text-gray-600 p-1"
                                >
                                    <Send className="w-5 h-5" />
                                </motion.button>
                            </div>
                            <Button className='w-1/3 sm:text-xs md:text-lg' onClick={() => setShowSolutionModal(true)} variant="primary">
                                <MouseIcon className="w-5 h-5" />
                                SUBMIT SOLUTION
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {/* Start/Resume Modal */}
            <AnimatePresence>
                {showModal && !loading && (
                    <motion.div
                        className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white rounded w-full max-w-md p-6 border-2 border-black"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                        >
                            <div className="mb-6">
                                <h3 className="text-lg font-bold mb-2 flex items-center gap-2">
                                    <Terminal className="w-5 h-5" />
                                    {existingAttempt ? "RESUME CHALLENGE" : "INITIALIZE CHALLENGE"}
                                </h3>
                                <p className="text-sm">
                                    {existingAttempt
                                        ? "Previous attempt detected. Continue existing challenge new?"
                                        : "Ready to begin challenge?"}
                                </p>
                            </div>
                            <div className="flex justify-end gap-3">
                                {existingAttempt ? (
                                    <>
                                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                                            CANCEL
                                        </Button>
                                        <Button variant="secondary" onClick={handleResumeAttempt}>
                                            RESUME
                                        </Button>
                                        <Button variant="primary" onClick={handleStartFresh}>
                                            NEW
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                                            CANCEL
                                        </Button>
                                        <Button variant="primary" onClick={handleStartChallenge}>
                                            START
                                        </Button>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Submit Solution Modal */}
            <AnimatePresence>
                {showSolutionModal && (
                    <motion.div
                        className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white rounded max-w-md w-full p-6 border-2 border-black"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                        >
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-bold flex items-center gap-2">
                                    <Zap className="w-5 h-5" />
                                    SUBMIT SOLUTION
                                </h3>
                                <button
                                    onClick={() => {
                                        setShowSolutionModal(false);
                                        setSolutionFeedback(null);
                                    }}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>

                            <textarea
                                value={solutionInput}
                                onChange={(e) => setSolutionInput(e.target.value)}
                                className="w-full h-40 p-4 rounded bg-slate-100 border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black font-mono mb-4"
                                placeholder="ENTER SOLUTION CODE..."
                            />

                            {solutionFeedback && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className="mb-4 p-3 rounded bg-yellow-300 border-2 border-black font-bold"
                                >
                                    {solutionFeedback}
                                </motion.div>
                            )}

                            <div className="flex justify-end gap-3">
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setShowSolutionModal(false);
                                        setSolutionFeedback(null);
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button variant="primary" onClick={handleSolutionSubmit}>
                                    EXECUTE
                                </Button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Attempt Ended Modal */}
            <AnimatePresence>
                {showAttemptEndedModal && (
                    <AttemptEndedModal onClose={() => setShowAttemptEndedModal(false)} />
                )}
            </AnimatePresence>

            {/* Send to Bland Modal */}
            <AnimatePresence>
                {showBlandModal && (
                    <motion.div
                        className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white rounded max-w-md w-full p-6 border-2 border-black"
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                        >
                            <div className="flex justify-between items-center mb-6">
                                <h3 className="text-lg font-bold flex items-center gap-2">
                                    <Shield className="w-5 h-5" />
                                    CHALLENGE COMPLETED
                                </h3>
                                <button
                                    onClick={() => setShowBlandModal(false)}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>

                            {(blandSuccessMessage || blandErrorMessage) && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className={`mb-6 p-3 rounded border-2 ${blandSuccessMessage ? 'bg-green-300 border-black' : 'bg-red-300 border-black'
                                        }`}
                                >
                                    {blandSuccessMessage || blandErrorMessage}
                                </motion.div>
                            )}

                            <div className="space-y-4">
                                <div>
                                    Congratulations! You have solved the challenge. You are truly exceptional at prompting.
                                    We'd love to learn more about you, please fill out the below if you are okay with the Bland team reaching out :)
                                    <label className="block text-sm font-bold mb-1">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        value={blandName}
                                        onChange={(e) => setBlandName(e.target.value)}
                                        className="w-full p-3 rounded bg-slate-100 border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black font-mono"
                                        placeholder="ENTER NAME"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">
                                        COMM CHANNEL
                                    </label>
                                    <input
                                        type="email"
                                        value={blandEmail}
                                        onChange={(e) => setBlandEmail(e.target.value)}
                                        className="w-full p-3 rounded bg-slate-100 border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black font-mono"
                                        placeholder="ENTER EMAIL"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">
                                        LINKEDIN <span className="text-gray-400">(OPTIONAL)</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={blandLinkedIn}
                                        onChange={(e) => setBlandLinkedIn(e.target.value)}
                                        className="w-full p-3 rounded bg-slate-100 border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black font-mono"
                                        placeholder="ENTER LINKEDIN URL"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end gap-3 mt-6">
                                <Button variant="secondary" onClick={() => setShowBlandModal(false)}>
                                    CANCEL
                                </Button>
                                <Button variant="primary" onClick={handleSendToBland}>
                                    TRANSMIT
                                </Button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Show "No Account" modal if we detect user isn't verified */}
            <AnimatePresence>
                {showNoAccountModal && (
                    <NoAccountModal
                        onClose={() => {
                            setShowNoAccountModal(false);
                            navigate('/'); // Could optionally route them home or to signup
                        }}
                    />
                )}
            </AnimatePresence>

            {/* Global style overrides */}
            <style jsx global>{`
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 0;
        }
        ::-webkit-scrollbar-thumb {
          background: #000;
          border-radius: 0;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #333;
        }
        .overflow-y-auto {
          scroll-behavior: smooth;
        }
        *:focus-visible {
          outline: none;
          box-shadow: 0 0 0 2px white, 0 0 0 4px #000;
        }
      `}</style>
        </div>
    );
}

export default PromptPage;
