import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Confetti from 'react-confetti';
import { Terminal, Lock, Unlock, Zap, Brain, ChevronRight } from 'lucide-react';

function Home() {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [welcomeMsg, setWelcomeMsg] = useState('');

    // Track if user is logged in, and if so, store their username
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState('');

    const navigate = useNavigate();

    // --- Signup states ---
    const [signUpLoading, setSignUpLoading] = useState(false);
    const [signUpToast, setSignUpToast] = useState(null); // text for success/error
    const [signUpToastType, setSignUpToastType] = useState(''); // "success" or "error"
    const [signUpConfetti, setSignUpConfetti] = useState(false);

    // Clear signup toast after 3 seconds
    useEffect(() => {
        if (signUpToast) {
            const timer = setTimeout(() => {
                setSignUpToast(null);
                setSignUpToastType('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [signUpToast]);

    // Clear confetti after 5 seconds
    useEffect(() => {
        if (signUpConfetti) {
            const timer = setTimeout(() => {
                setSignUpConfetti(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [signUpConfetti]);

    // Check localStorage for existing session
    useEffect(() => {
        const token = localStorage.getItem('jwt');
        const username = localStorage.getItem('username');
        if (token && username) {
            setLoggedIn(true);
            setLoggedInUser(username);
            setWelcomeMsg(`Welcome back, Agent ${username}!`);
        }
    }, []);

    // Fetch prompts
    useEffect(() => {
        const fetchPrompts = async () => {
            try {
                const response = await fetch('/api/prompts');
                if (!response.ok) throw new Error('Failed to fetch prompts');
                const data = await response.json();
                setPrompts(data.prompts);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchPrompts();
    }, []);

    // =========== Auth Handlers ===========

    // 1) SIGNUP => Then auto login
    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        setSignUpLoading(true);

        const formData = new FormData(e.target);
        const signupData = {
            username: formData.get('username'),
            email: formData.get('email'),
            password: formData.get('password'),
        };

        try {
            // First, call /api/signup
            const signupResponse = await fetch('/api/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(signupData),
            });
            const signupResult = await signupResponse.json();

            setSignUpLoading(false);

            if (!signupResponse.ok) {
                // signup error
                setSignUpToast(signupResult?.error || 'Signup failed');
                setSignUpToastType('error');
                return;
            }

            // If we get here, signup is successful
            setSignUpToast('Signup Successful!');
            setSignUpToastType('success');
            setSignUpConfetti(true);

            // Now automatically call /api/login using the same credentials
            const loginPayload = {
                email: signupData.email,
                password: signupData.password,
            };
            const loginResponse = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginPayload),
            });
            const loginResult = await loginResponse.json();

            if (loginResponse.ok) {
                // Save token + username in localStorage
                localStorage.setItem('jwt', loginResult.token);
                if (loginResult.user?.username) {
                    localStorage.setItem('username', loginResult.user.username);
                    setLoggedInUser(loginResult.user.username);
                    setWelcomeMsg(`Welcome, Agent ${loginResult.user.username}!`);
                }
                setLoggedIn(true);
            } else {
                // login error
                setSignUpToast(loginResult?.error || 'Auto-login failed');
                setSignUpToastType('error');
            }

            // Close modals in any case
            setShowSignup(false);
            setShowLogin(false);

            console.log('Signup response:', signupResult);
            console.log('Auto-login response:', loginResult);
        } catch (err) {
            setSignUpLoading(false);
            setSignUpToast(err.message || 'Signup error');
            setSignUpToastType('error');
        }
    };

    // 2) LOGIN
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            email: formData.get('email'),
            password: formData.get('password'),
        };

        const response = await fetch('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });
        const result = await response.json();

        if (response.ok) {
            // Save token + username
            localStorage.setItem('jwt', result.token);
            if (result.user?.username) {
                localStorage.setItem('username', result.user.username);
                setLoggedInUser(result.user.username);
                setWelcomeMsg(`Welcome, Agent ${result.user.username}!`);
            }
            setLoggedIn(true);

            setShowLogin(false);
            setShowSignup(false);
        } else {
            console.error('Login error:', result.error);
        }
    };

    // SIGN OUT
    const handleSignOut = () => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('username');
        setLoggedIn(false);
        setLoggedInUser('');
        setWelcomeMsg('');
    };

    // =========== Modal Variants ===========
    const modalVariants = {
        hidden: { y: -20, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { type: 'spring', damping: 25 } },
        exit: { y: 20, opacity: 0, transition: { duration: 0.2 } },
    };

    return (
        <div className="bg-slate-100 min-h-screen font-mono relative">
            {/* Confetti on success */}
            {signUpConfetti && (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    numberOfPieces={250}
                    recycle={false}
                />
            )}

            {/* Signup Toast (success or error) */}
            <AnimatePresence>
                {signUpToast && (
                    <motion.div
                        className={`fixed top-16 right-4 z-50 px-4 py-2 rounded shadow-md font-bold ${signUpToastType === 'success'
                                ? 'bg-green-200 text-green-800 border border-green-300'
                                : 'bg-red-200 text-red-800 border border-red-300'
                            }`}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 50 }}
                        transition={{ duration: 0.3 }}
                    >
                        {signUpToast}
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Welcome Message */}
            {welcomeMsg && (
                <motion.div
                    className="absolute top-0 w-full text-center py-2 bg-green-400 text-black font-bold border-b-2 border-green-500"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {welcomeMsg}
                </motion.div>
            )}

            {/* Auth Modals */}
            <AnimatePresence>
                {(showSignup || showLogin) && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 px-4">
                        <motion.div
                            className="bg-white w-full max-w-md p-8 rounded border-2 border-black"
                            variants={modalVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <div className="flex items-center gap-2 mb-6">
                                {showSignup ? <Lock size={24} /> : <Unlock size={24} />}
                                <h2 className="text-2xl font-bold">
                                    {showSignup ? 'Initialize Agent Profile' : 'Agent Authentication'}
                                </h2>
                            </div>
                            <form
                                onSubmit={showSignup ? handleSignupSubmit : handleLoginSubmit}
                                className="space-y-4"
                            >
                                {showSignup && (
                                    <div>
                                        <label className="block text-sm font-bold mb-1">Username</label>
                                        <input
                                            type="text"
                                            name="username"
                                            required
                                            className="w-full px-4 py-2 bg-slate-100 border-2 border-black rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                                        />
                                    </div>
                                )}
                                <div>
                                    <label className="block text-sm font-bold mb-1">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        className="w-full px-4 py-2 bg-slate-100 border-2 border-black rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-bold mb-1">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        required
                                        className="w-full px-4 py-2 bg-slate-100 border-2 border-black rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                                    />
                                </div>

                                {/* Show "Loading..." if signUpLoading is true */}
                                {showSignup && signUpLoading && (
                                    <div className="text-sm font-bold text-blue-600">Initializing...</div>
                                )}

                                <div className="flex justify-between items-center pt-4">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setShowSignup(false);
                                            setShowLogin(false);
                                        }}
                                        className="px-4 py-2 text-sm font-bold hover:text-red-600 transition"
                                    >
                                        ABORT
                                    </button>
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        type="submit"
                                        className="px-6 py-2 bg-black text-white font-bold rounded hover:bg-gray-800 transition flex items-center gap-2"
                                    >
                                        {showSignup ? 'INITIALIZE' : 'AUTHENTICATE'}
                                        <ChevronRight size={16} />
                                    </motion.button>
                                </div>
                            </form>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>

            {/* Header */}
            <header className="bg-white border-b-2 border-black py-4 px-4 sm:px-6">
                <div className="max-w-6xl mx-auto flex justify-between items-center">
                    <div className="flex items-center gap-2">
                        <Terminal className="w-6 h-6" />
                        <h1 className="text-2xl font-bold">Prompt Capture The Flag</h1>
                    </div>

                    <div className="space-x-4 flex items-center">
                        {loggedIn && (
                            <span className="text-sm font-bold text-gray-600">
                                Logged in as {loggedInUser}
                            </span>
                        )}
                        {loggedIn ? (
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => {
                                    localStorage.removeItem('jwt');
                                    localStorage.removeItem('username');
                                    setLoggedIn(false);
                                    setLoggedInUser('');
                                    setWelcomeMsg('');
                                }}
                                className="px-4 py-2 bg-black text-white font-bold rounded hover:bg-gray-800 transition"
                            >
                                SIGN OUT
                            </motion.button>
                        ) : (
                            <>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setShowLogin(true)}
                                    className="px-4 py-2 font-bold hover:text-blue-600 transition"
                                >
                                    LOGIN
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => setShowSignup(true)}
                                    className="px-4 py-2 bg-black text-white font-bold rounded hover:bg-gray-800 transition"
                                >
                                    SIGNUP
                                </motion.button>
                            </>
                        )}
                    </div>
                </div>
            </header>

            {/* Hero Section */}
            <section className="py-20 bg-white border-b-2 border-black px-4 sm:px-6">
                <div className="max-w-6xl mx-auto text-center">
                    <div className="flex justify-center mb-6">
                        <Brain className="w-16 h-16" />
                    </div>
                    <h2 className="text-3xl sm:text-4xl font-bold mb-4">
                        Capture The Flag
                    </h2>
                    <p className="text-base sm:text-lg mb-8 max-w-2xl mx-auto">
                        Below are a series of prompt challenges. The goal is to break the AI and capture the flag.
                        Do your best — they are designed to be difficult. Each prompt challenge has basic rules and guidelines.
                    </p>
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="px-8 py-3 bg-black text-white font-bold rounded transition flex items-center gap-2 mx-auto"
                    >
                        BEGIN TRAINING <Zap size={20} />
                    </motion.button>
                </div>
            </section>

            {/* Challenges Section */}
            <section className="py-16 px-4 sm:px-6">
                <div className="max-w-6xl mx-auto">
                    <h3 className="text-2xl font-bold mb-8 flex items-center gap-2">
                        <Terminal className="w-6 h-6" />
                        ACTIVE SCENARIOS
                    </h3>
                    {loading ? (
                        <p className="font-bold">LOADING SCENARIOS...</p>
                    ) : error ? (
                        <p className="text-red-600 font-bold">{error}</p>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {prompts.map((prompt) => (
                                <motion.div
                                    key={prompt.id}
                                    className="bg-white p-6 rounded border-2 border-black hover:border-blue-500 cursor-pointer"
                                    onClick={() => navigate(`/prompt?id=${prompt.id}`)}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="px-3 py-1 bg-yellow-300 text-black font-bold rounded">
                                            LEVEL {prompt.difficulty_level}
                                        </span>
                                        <span className="font-bold">
                                            {prompt.allowed_messages} Messages
                                        </span>
                                    </div>
                                    <p className="font-medium mb-4 break-words">
                                        {prompt.description}
                                    </p>
                                </motion.div>
                            ))}
                        </div>
                    )}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-black text-white py-4 text-center font-bold mt-8 absolute bottom-0 w-full">
                <p>© 2025 Bland // TRAIN YOUR MIND // MASTER THE MACHINE</p>
            </footer>
        </div>
    );
}

export default Home;
